import $ from 'jquery';
import 'bootstrap';
//import AOS from 'aos'
import Pace from 'pace-js/pace'
window.jQuery = $;
window.$ = $;
require("@fancyapps/fancybox");
import 'slick-carousel';
import 'jquery-mask-plugin';

// ====================================== Replace fa-icons with SVGs ===========================

$(()=>{
// ====================================== Animate on scroll ====================================

   // AOS.init();

// ====================================== Loader ===============================================

    Pace.start();

// ====================================== Navbar icon animation ================================

    $('.nav-button').on('click', function () {
        $('.animated-icon1').toggleClass('open');
    });

// ===================================== Custom File Input =====================================

    $('#customFile').change((e)=>{
        let filename = $(e.target).val();
        filename = filename.substring(filename.lastIndexOf("\\") + 1, filename.length);
        $(e.target).next('.custom-file-label').html(filename);
    });

// =================================== scroll to top button ====================================
    $(window).scroll(function () {
        if ($(this).scrollTop() > 0) {
            $('.scrollTop').fadeIn();
        } else {
            $('.scrollTop').fadeOut();
        }
    });
    $('.scrollTop').click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 400);
        return false;
    });

// ======================================= Jquery mask =========================================
$('.phone').mask('+7 (000) 000-00-00');

// ========================================== Survey ===========================================
var survey = new Object();
survey.currentAction = 0;
survey.prev = function() {
        if(this.currentAction > 0) {
            --this.currentAction;
            return true;
        } else {
            return false;
        }
    };
survey.next = function() {
        if (this.currentAction >= this.actions.length-1) {
            return false;
        } else {
            ++this.currentAction;
            return true;
        }
    };
survey.current = function() {
    return this.actions[this.currentAction];
}
survey.template = `
<div id="survey">
    <div class="survey-wrap">
        <div class="title"><h1></h1></div>
        <div class="buttons d-flex flex-row justify-content-around">
            <button class="btn custom-btn" type="button" onclick="prevQ()" id="prev">prev</button>
            <button class="btn custom-btn" type="button" onclick="nextQ()" id="next">next</button>
        </div>
    </div>
</div>
`;
survey.actions = [];
var act = new Object();
act.result = "Рассчитать стоимость";
act.number = 2;
var act2 = new Object();
act2.result = "Вопрос 2";
act2.number = 456;
survey.actions.push(act);
survey.actions.push(act2);


var nextQ = function() {
    $.fancybox.close();
    var survey = window.survey;
    var html = $(survey.template);
    survey.next();
    html.find('.title h1').text(survey.actions[survey.currentAction].result);
        $.fancybox.open(
            {
                src: html,
                type: 'html'
            }
        );
}

var prevQ = function() {
    $.fancybox.close();
    var survey = window.survey;
    var html = $(survey.template);
    survey.prev();
    html.find('.title h1').text(survey.actions[survey.currentAction].result);
        $.fancybox.open(
            {
                src: html,
                type: 'html'
            }
        );
}

survey.start = function() {
var html = $("#survey-1");
html.find('.title h2').text(survey.actions[survey.currentAction].result);
    $.fancybox.open(
        {
            src: html,
            type: 'html'
        }
    );
}
window.prevQ = prevQ;
window.nextQ = nextQ;
window.survey = survey;


// ====================================== Slick sliders ========================================

    let slickCarousel = {
        useTransform: true,
        infinite: true,
        arrows: false,
        dots: true,
        autoplay: true,
        slidesToShow: 1,
        cssEase: "ease-out",
        appendArrows: $('#slickDotsCarousel'),
        prevArrow: $('#slickArrowsCarousel .prev'),
        nextArrow: $('#slickArrowsCarousel .next')
    };

    let slickSolutions = {
        infinite: false,
        arrows: true,
        dots: false,
        autoplay: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        appendArrows: $('#slickArrowsSolutions'),
        prevArrow: $('#slickArrowsSolutions .prev'),
        nextArrow: $('#slickArrowsSolutions .next'),
        responsive: [{
            breakpoint: '768',
            settings: {
                arrows: false,
                dots: true,
                slidesToShow: 1
            }
        }]
    };

    let slickPortfolio = {
        infinite: false,
        arrows: true,
        dots: false,
        autoplay: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        appendArrows: $('#slickArrowsPortfolio'),
        prevArrow: $('#slickArrowsPortfolio .prev'),
        nextArrow: $('#slickArrowsPortfolio .next'),
        responsive: [{
            breakpoint: '768',
            settings: {
                arrows: false,
                dots: false,
                slidesToShow: 1
            }
        }]
    };

    let slickChallenge = {
        infinite: false,
        arrows: false,
        dots: true,
        autoplay: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        appendArrows: $('#slickArrowsChallenge'),
        prevArrow: $('#slickArrowsChallenge .prev'),
        nextArrow: $('#slickArrowsChallenge .next'),
        responsive: [{
            breakpoint: '768',
            settings: {
                slidesToShow: 1
            }
        }]
    };

    let slickVideo = {
        infinite: false,
        arrows: true,
        dots: false,
        autoplay: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        appendArrows: $('#slickArrowsVideo'),
        prevArrow: $('#slickArrowsVideo .prev'),
        nextArrow: $('#slickArrowsVideo .next'),
        responsive: [{
            breakpoint: '768',
            settings: {
                arrows: false,
                dots: false,
                slidesToShow: 1
            }
        }]
    };

    let slickTeam = {
        infinite: false,
        arrows: true,
        dots: false,
        autoplay: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        appendArrows: $('#slickArrowsTeam'),
        prevArrow: $('#slickArrowsTeam .prev'),
        nextArrow: $('#slickArrowsTeam .next'),
        responsive: [{
            breakpoint: '768',
            settings: {
                arrows: false,
                dots: true,
                slidesToShow: 1
            }
        }]
    };

    let slickNews = {
        infinite: false,
        arrows: true,
        dots: false,
        autoplay: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        appendArrows: $('#arrowsNews'),
        prevArrow: $('#arrowsNews .prev'),
        nextArrow: $('#arrowsNews .next'),
        responsive: [{
            breakpoint: '768',
            settings: {
                arrows: false,
                dots: true,
                slidesToShow: 1
            }
        }]
    };

    let slickPartners = {
        infinite: false,
        arrows: true,
        dots: false,
        autoplay: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        appendArrows: $('#slickArrowsPartners'),
        prevArrow: $('#slickArrowsPartners .prev'),
        nextArrow: $('#slickArrowsPartners .next'),
        responsive: [{
            breakpoint: '768',
            settings: {
                arrows: false,
                dots: false,
                slidesToShow: 1
            }
        }]
    };

    let slickBrands = {
        infinite: false,
        arrows: true,
        dots: false,
        autoplay: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        appendArrows: $('#arrowsBrands'),
        prevArrow: $('#arrowsBrands .prev'),
        nextArrow: $('#arrowsBrands .next'),
        responsive: [{
            breakpoint: '768',
            settings: {
                arrows: false,
                dots: true,
                slidesToShow: 1
            }
        }]
    };
/*
    let slickPortfolio = {
        infinite: false,
        arrows: true,
        dots: false,
        autoplay: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        appendArrows: $('#arrowsPartners'),
        prevArrow: $('#arrowsPortfolio .prev'),
        nextArrow: $('#arrowsPortfolio .next'),
        responsive: [{
            breakpoint: '768',
            settings: {
                arrows: false,
                dots: true,
                slidesToShow: 1
            }
        }]
    };
*/
    let slickServicePortfolio = {
        infinite: false,
        arrows: true,
        dots: false,
        autoplay: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        appendArrows: $('#slickArrowsServicePortfolio'),
        prevArrow: $('#slickArrowsServicePortfolio .prev'),
        nextArrow: $('#slickArrowsServicePortfolio .next'),
        responsive: [{
            breakpoint: '768',
            settings: {
                arrows: false,
                dots: true,
                slidesToShow: 1
            }
        }]
    };

    let slickAdditionalSvc = {
        infinite: false,
        arrows: true,
        dots: false,
        autoplay: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        appendArrows: $('#slickArrowsAdditionalSvc'),
        prevArrow: $('#slickArrowsAdditionalSvc .prev'),
        nextArrow: $('#slickArrowsAdditionalSvc .next'),
        responsive: [{
            breakpoint: '768',
            settings: {
                arrows: false,
                dots: true,
                slidesToShow: 1
            }
        }]
    };

    let slickHistory = {
        infinite: false,
        arrows: true,
        dots: false,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendArrows: $('#arrowsHistory'),
        prevArrow: $('#arrowsHistory .prev'),
        nextArrow: $('#arrowsHistory .next'),
        responsive: [{
            breakpoint: '768',
            settings: {
                arrows: false,
                dots: true,
                slidesToShow: 1
            }
        }]
    };

    let slickRelated = {
        infinite: false,
        arrows: true,
        dots: false,
        autoplay: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        appendArrows: $('#arrowsRelated'),
        prevArrow: $('#arrowsRelated .prev'),
        nextArrow: $('#arrowsRelated .next'),
        responsive: [{
            breakpoint: '768',
            settings: {
                arrows: false,
                dots: true,
                slidesToShow: 1
            }
        }]
    };

    $('.carousel').slick(slickCarousel);
    $('.slick-solutions').slick(slickSolutions);
    $('.slick-challenge').slick(slickChallenge);
    $('.slick-video').slick(slickVideo);
    $('.slick-team').slick(slickTeam);
    $('.slick-news').slick(slickNews);
    $('.slick-partners').slick(slickPartners);
    $('.slick-brands').slick(slickBrands);
    $('.slick-portfolio').slick(slickPortfolio);
    $('.slick-history').slick(slickHistory);
    $('.slick-related').slick(slickRelated);
    $('.slick-service-portfolio').slick(slickServicePortfolio)
    $('.slick-AdditionalSvc').slick(slickAdditionalSvc)

});
